import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/home/Home';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Navbar from './components/navbar/Navbar';
import Authentication from './pages/auth/Authentication';
import Dashboard from './pages/dashboard/Dashboard'; // Example protected page
import Profile from './pages/profile/Profile';
import Contact from './pages/contact/Contact';

const App = () => {
  const ambulanceId = localStorage.getItem('hb_ambulance_id');

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        {/* If not authenticated, redirect all routes to /authentication */}
        {!ambulanceId ? (
          <>
            <Route path="/authentication" element={<Authentication />} />
            <Route path="*" element={<Navigate to="/authentication" />} />
          </>
        ) : (
          <>
            {/* Authenticated routes */}
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact" element={<Contact />} />
            {/* Add more routes as needed */}
            <Route path="*" element={<Navigate to="/" />} /> {/* Fallback to Home if route not found */}
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;

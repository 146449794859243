import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // Check if hb_ambulance_id is present in local storage
  useEffect(() => {
    const ambulanceId = localStorage.getItem('hb_ambulance_id');
    setIsLoggedIn(!!ambulanceId); // Convert value to boolean
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  // Handle logout action
  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      localStorage.removeItem('hb_ambulance_id');
      setIsLoggedIn(false);
      window.location.reload(); 
    }
  };

  useEffect(() => {
    // Add event listener to close the mobile menu when clicking outside
    const handleOutsideClick = (e) => {
      if (isMobileMenuOpen && !e.target.closest('.navbar')) {
        closeMobileMenu();
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMobileMenuOpen]);

  return (
    <nav className="navbar">
      <Link to="/" className="brand" onClick={closeMobileMenu}>
        Ambulance Admin
      </Link>

      <div className={`menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <Link to="/" onClick={closeMobileMenu}>
          Home
        </Link>

        <Link to="/profile" onClick={closeMobileMenu}>
          Profile
        </Link>

        <Link to="/contact" onClick={closeMobileMenu}>
          Contact Us
        </Link>
        {/* Change button based on login status */}
        {isLoggedIn ? (
          <button className="book-meeting1" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          <Link to="/authentication" onClick={closeMobileMenu}>
            <button className="book-meeting1">Login</button>
          </Link>
        )}
      </div>
{/* Change button based on login status */}
{isLoggedIn ? (
          <button className="book-meeting" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          <Link to="/authentication" onClick={closeMobileMenu}>
            <button className="book-meeting">Login</button>
          </Link>
        )}
      <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;

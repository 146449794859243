import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Authentication.css';

const Authentication = () => {
    const [formType, setFormType] = useState('login');
    const [formData, setFormData] = useState({
        owner_name: '',
        phone_number: '',
        email_address: '',
        ambulance_type: '',
        license_number: '',
        city: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // for navigating to the dashboard

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const url = formType === 'login' 
            ? 'https://api.thehummingbird.org.in/api/ambulances/login' 
            : 'https://api.thehummingbird.org.in/api/ambulances/register';

        // Prepare the correct payload for login and register
        const payload = formType === 'login' 
            ? { email_address: formData.email_address, password: formData.password }
            : formData;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Something went wrong');
            }

            // If login or register is successful
            if (formType === 'login') {
                // Store the ambulance ID in local storage
                localStorage.setItem('hb_ambulance_id', result.id);
                alert('Login successful! Redirecting to dashboard...');
                window.location.reload(); 

                // Navigate to the dashboard
                navigate('/dashboard');
            } else {
                alert('Registration successful! Please login.');
                setFormType('login');
                setFormData({
                    owner_name: '',
                    phone_number: '',
                    email_address: '',
                    ambulance_type: '',
                    license_number: '',
                    city: '',
                    password: '',
                });
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="authentication-container">
            <h1>{formType === 'login' ? 'Ambulance Login' : 'Register Your Ambulance'}</h1>
            <form onSubmit={handleSubmit} className="authentication-form">
                {formType === 'register' && (
                    <>
                        <input
                            type="text"
                            name="owner_name"
                            value={formData.owner_name}
                            onChange={handleChange}
                            placeholder="Owner's Name"
                            required
                        />
                        <input
                            type="text"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                        <input
                            type="text"
                            name="ambulance_type"
                            value={formData.ambulance_type}
                            onChange={handleChange}
                            placeholder="Ambulance Type"
                            required
                        />
                        <input
                            type="text"
                            name="license_number"
                            value={formData.license_number}
                            onChange={handleChange}
                            placeholder="License Number"
                            required
                        />
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="City"
                            required
                        />
                    </>
                )}
                <input
                    type="email"
                    name="email_address"
                    value={formData.email_address}
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                />
                <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : formType === 'login' ? 'Login' : 'Register'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>
            <div className="toggle-form">
                <p>
                    {formType === 'login'
                        ? 'Don\'t have an account?'
                        : 'Already have an account?'}
                    <button onClick={() => setFormType(formType === 'login' ? 'register' : 'login')}>
                        {formType === 'login' ? 'Register' : 'Login'}
                    </button>
                </p>
            </div>
        </div>
    );
};

export default Authentication;

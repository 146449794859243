import React, { useEffect, useState } from 'react';
import './Profile.css';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    owner_name: '',
    phone_number: '',
    email_address: '',
    ambulance_type: '',
    license_number: '',
    city: ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      const hbAmbulanceId = localStorage.getItem('hb_ambulance_id'); // Retrieve the ambulance ID from local storage
      try {
        const response = await fetch(`https://api.thehummingbird.org.in/api/ambulances/${hbAmbulanceId}`);
        const data = await response.json();
        setProfile(data);
        setFormData({
          owner_name: data.owner_name,
          phone_number: data.phone_number,
          email_address: data.email_address,
          ambulance_type: data.ambulance_type,
          license_number: data.license_number,
          city: data.city,
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hbAmbulanceId = localStorage.getItem('hb_ambulance_id');

    try {
      const response = await fetch(`https://api.thehummingbird.org.in/api/ambulances/${hbAmbulanceId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const updatedProfile = await response.json();
        setProfile(updatedProfile);
        setIsEditModalOpen(false); // Close the modal on success
      } else {
        console.error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-section">
      <div className="profile-card">
        <h2 className="profile-header">Ambulance Profile</h2>
        <div className="profile-content">
          <div className="profile-item">
            <strong>Owner Name:</strong> <span>{profile.owner_name}</span>
          </div>
          <div className="profile-item">
            <strong>Phone Number:</strong> <span>{profile.phone_number}</span>
          </div>
          <div className="profile-item">
            <strong>Email Address:</strong> <span>{profile.email_address}</span>
          </div>
          <div className="profile-item">
            <strong>Ambulance Type:</strong> <span>{profile.ambulance_type}</span>
          </div>
          <div className="profile-item">
            <strong>License Number:</strong> <span>{profile.license_number}</span>
          </div>
          <div className="profile-item">
            <strong>City:</strong> <span>{profile.city}</span>
          </div>
          <div className="profile-item">
            <strong>Joined On:</strong> <span>{new Date(profile.created_at).toLocaleDateString()}</span>
          </div>
          <div className="profile-item">
            <strong>Approval By Admin:</strong> <span>{profile.approval_status || 'Pending'}</span>
          </div>
        </div>
        <button className="edit-button" onClick={handleEditClick}>Edit Profile</button>
      </div>

      {isEditModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Edit Profile</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Owner Name:</label>
                <input
                  type="text"
                  name="owner_name"
                  value={formData.owner_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Phone Number:</label>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email Address:</label>
                <input
                  type="email"
                  name="email_address"
                  value={formData.email_address}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Ambulance Type:</label>
                <input
                  type="text"
                  name="ambulance_type"
                  value={formData.ambulance_type}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>License Number:</label>
                <input
                  type="text"
                  name="license_number"
                  value={formData.license_number}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type="submit" className="save-button">Save</button>
              <button type="button" className="cancel-button" onClick={() => setIsEditModalOpen(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;

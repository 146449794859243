import React, { useEffect, useState } from 'react';
import './Dashboard.css';

const Dashboard = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await fetch('https://api.thehummingbird.org.in/api/ambulance-bookings');
        const data = await response.json();

        const hbAmbulanceId = localStorage.getItem('hb_ambulance_id'); // Get the local storage item

        // Filter requests to only show pending or approved by the current ambulance (hb_ambulance_id)
        const filteredRequests = data.filter(
          (request) => request.approval_status === 'pending' || request.approved_by === hbAmbulanceId
        );

        // Fetch location names for each request and sort requests by `created_at` in descending order
        const requestsWithLocations = await Promise.all(
          filteredRequests
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at in descending order
            .map(async (request) => {
              const [latitude, longitude] = request.coordinates.split(',');
              const locationName = await fetchLocationName(latitude, longitude);
              return { ...request, locationName, latitude, longitude };
            })
        );

        setRequests(requestsWithLocations);
      } catch (error) {
        console.error('Error fetching requests:', error);
      }
    };

    fetchRequests(); // Initial fetch

    const intervalId = setInterval(fetchRequests, 15000); // Refetch every 15 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const fetchLocationName = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=66c10688f024d156456458kmw93ae35`);
      const data = await response.json();
      return data.display_name || 'Unknown location';
    } catch (error) {
      console.error('Error fetching location name:', error);
      return 'Error fetching location';
    }
  };

  const handleUpdateStatus = async (id, status) => {
    const hbAmbulanceId = localStorage.getItem('hb_ambulance_id'); // Get the local storage item

    try {
      await fetch(`https://api.thehummingbird.org.in/api/ambulance-bookings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          approval_status: status,
          approved_by: status === 'approved' ? hbAmbulanceId : null, // Set approved_by only if approved
        }),
      });
      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === id ? { ...request, approval_status: status } : request
        )
      );
    } catch (error) {
      console.error('Error updating request status:', error);
    }
  };

  const handleCallOwner = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, '_blank');
  };

  const getGoogleMapsUrl = (latitude, longitude) => {
    return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
  };

  return (
    <div className="dashboard-container">
      <h1>Ambulance Booking Requests</h1>
      <div className="requests-list">
        {requests.length > 0 ? (
          requests.map((request) => (
            <div key={request.id} className="request-card">
              <h3>Request ID: {request.id}</h3>
              <p><strong>Phone Number:</strong> {request.phone_number}</p>
              <p><strong>Coordinates:</strong> {request.coordinates}</p>
              <p><strong>Location:</strong> {request.locationName}</p>
              <p><strong>Ambulance Type:</strong> {request.ambulance_type}</p>
              <p><strong>Status:</strong> {request.approval_status}</p>
              <p><strong>Booked At:</strong> {new Date(request.created_at).toLocaleString()}</p>
              <div className="buttons">
                {request.approval_status.toLowerCase() === 'pending' && (
                  <>
                    <button onClick={() => handleUpdateStatus(request.id, 'approved')} className="accept-button">Accept</button>
                    <button onClick={() => handleUpdateStatus(request.id, 'rejected')} className="deny-button">Deny</button>
                  </>
                )}
                <button onClick={() => handleCallOwner(request.phone_number)} className="call-button">Call Client</button>
                <button className="call-button">
                  <a href={getGoogleMapsUrl(request.latitude, request.longitude)} target="_blank" rel="noopener noreferrer" className="directions-link">
                    Show Directions
                  </a>
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className='no-bookings'>No Bookings for Ambulances found...(Our Software is in a Testing Phase)</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
